<template>
  <div>
    <div class="flix-form-group flix-text-center">
      <h3 class="flix-html-h3">{{ $tc('message.statistic', 2) }}</h3>
      <flix-statistic :user="$getUserVariables().user.md5_id" />
  </div>
  </div>
</template>
<script>
export default {
  components: {
  }
}
</script>
<style lang="sass">
</style>
